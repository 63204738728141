//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import DialogMixin from '~/mixins/DialogMixin'
import Divider from './Divider'

export default {
  components: {
    Divider
  },
  mixins: [DialogMixin],
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: ''
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    passwordConfirmation: {
      sameAsPassword: sameAs('password')
    },
    firstName: {
      required
    },
    lastName: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await this.$store.dispatch('signup', {
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName
          });
          this.$emit('close');
          this.$router.push({ name: 'projects' });
          this.$toast.default("You will need to confirm your email address by clicking on the link in the email that has just been sent to you. If you don't find it in your inbox check your spam folder.");
        } catch (error) {
          this.$emit('error', { error });
        }
      }
    },

    signUpFacebook() {
      // TODO
      console.log('Sign up Facebook');
    },

    signUpGoogle() {
      // TODO
      console.log('Sign up Google');
    }
  }
}
