export const getGraphQLErrorCode = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0]?.extensions.code;
  }
};

export const getGraphQLErrorMessage = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0].message;
  }
};

export const getErrorMessage = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0].message;
  } else {
    return error.message;
  }
};

export const getAppErrorCode = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0].extensions?.appCode;
  }
};
