//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from 'vuelidate/lib/validators'
import DialogMixin from '~/mixins/DialogMixin'

export default {
  mixins: [DialogMixin],
  data() {
    return {
      email: ''
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await this.$store.dispatch('resetPassword', {
            email: this.email
          });
          this.$emit('close');
          this.$toast.default("You will need to click on the link in the email that has just been sent to you. If you don't find it in your inbox check your spam folder.");
        } catch (error) {
          this.$emit('error', { error });
        }
      }
    }
  }
}
