import { toString } from '~/utils/string';
import AppStateItem from './AppStateItem';

export default superclass => class extends superclass {
  get _appStateItemsByKey() {
    if (this.__appStateItemsByKey == null) {
      this.__appStateItemsByKey = new Map();
      if (this.appStateItems) {
        this.appStateItems.forEach((appStateItem) => {
          this.__appStateItemsByKey.set(appStateItem.key, appStateItem);
        });
      }
    }
    return this.__appStateItemsByKey;
  }

  _getAppStateAsString(key, defaultValue) {
    const appStateItem = this._appStateItemsByKey.get(key);
    if (appStateItem) {
      return appStateItem.value;
    } else {
      return defaultValue;
    }
  }

  _getAppStateAsBoolean(key, defaultValue) {
    const appStateItem = this._appStateItemsByKey.get(key);
    if (appStateItem) {
      return appStateItem.asBoolean;
    } else {
      return defaultValue;
    }
  }

  _getAppStateAsNumber(key, defaultValue) {
    const appStateItem = this._appStateItemsByKey.get(key);
    if (appStateItem) {
      return appStateItem.asNumber;
    } else {
      return defaultValue;
    }
  }

  _getAppStateAsDayjsDate(key, defaultValue) {
    const appStateItem = this._appStateItemsByKey.get(key);
    if (appStateItem) {
      return appStateItem.asDayjsDate;
    } else {
      return defaultValue;
    }
  }

  async _setAppState(key, value, foreignKey = {}) {
    let appStateItem = this._appStateItemsByKey.get(key);
    if (appStateItem) {
      const strValue = toString(value);
      if (strValue === appStateItem.value) {
        return;
      }
      appStateItem.value = strValue;
      await appStateItem.save({ fields: ['value'] });
    } else {
      const result = await AppStateItem.insert({ data: { key, value: toString(value), ...foreignKey } });
      appStateItem = result.appStateItem[0];
      const fields = ['key', 'value'];
      const keys = Object.keys(foreignKey);
      if (keys.length > 0) {
        fields.push(keys[0]);
      }
      appStateItem = await appStateItem.save({ fields });
      this._appStateItemsByKey.set(key, appStateItem);
    }
  }

  _setAppStateWithDayjsDate(key, value, foreignKey = {}) {
    return this._setAppState(key, AppStateItem.fromDayjsDate(value), foreignKey);
  }
}
