//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutMixin from '~/mixins/LayoutMixin';
import ChangePasswordDialog from '~/components/ChangePasswordDialog';
import BasicDialog from '~/components/BasicDialog';
import SignUpDialog from '~/components/SignUpDialog';
import LogInDialog from '~/components/LogInDialog';
import ResetPasswordDialog from '~/components/ResetPasswordDialog';
import BasicDialogMixin from '~/mixins/BasicDialogMixin';
import ChangePasswordDialogMixin from '~/mixins/ChangePasswordDialogMixin';
import Constants from '~/constants';
import ContactDialog from '~/components/ContactDialog';

export default {
  mixins: [LayoutMixin, BasicDialogMixin, ChangePasswordDialogMixin],
  components: {
    SignUpDialog,
    LogInDialog,
    ResetPasswordDialog,
    BasicDialog,
    ChangePasswordDialog,
    ContactDialog
  },
  data() {
    return {
      currentDialog: null
    }
  },
  created() {
    this.$nuxt.$on('show-sign-up-dialog', () => {
      this.showSignUpDialog();
    });

    this.$nuxt.$on('show-log-in-dialog', () => {
      this.showLogInDialog();
    });

    this.$nuxt.$on('show-change-password-dialog', ({ token, email }) => {
      this.showChangePasswordDialog(token, email);
    });
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  methods: {
    showSignUpDialog() {
      if (window.innerWidth < Constants.LARGE_SCREEN_MIN_WIDTH) {
        this.showBasicDialog('The application is currently not available on mobile, sign-up on a desktop or a laptop.',
          { showModalBackground: true });
      } else {
        this.currentDialog = 'SignUpDialog';
      }
    },
    showLogInDialog() {
      if (window.innerWidth < Constants.LARGE_SCREEN_MIN_WIDTH) {
        this.showBasicDialog('The application is currently not available on mobile, log-in on a desktop or a laptop.',
          { showModalBackground: true });
      } else {
        this.currentDialog = 'LogInDialog';
      }
    },
    showResetPasswordDialog(email = undefined) {
      this.currentDialog = 'ResetPasswordDialog';
    },
    hideDialog() {
      this.currentDialog = null;
    },
    showContactDialog() {
      this.currentDialog = 'ContactDialog';
    }
  }
}
