import BaseModel from './BaseModel';

export default class Item extends BaseModel {
  static fields() {
    return {
      ...super.fields(),
      title: this.attr(''),
      index: this.attr(0),
      duration: this.attr(null),
      note: this.attr(null)
    }
  }

  get displayAsComputed() {
    return false;
  }

  /*
  get effectiveStartDate() {}
  get effectiveEndDate() {}
  */

  isValidDuration(duration) {
    return duration != null && duration > 0;
  }
}
