import { Observable } from 'apollo-link';

// SCSS variables are imported as strings, numbers need to be converted to numbers
export const processScssVars = (variables) => {
  const retObj = {};
  for (const variableName of Object.keys(variables)) {
    let value = variables[variableName];
    if (typeof value === 'string' && !isNaN(value)) {
      value = parseFloat(value);
    }
    retObj[variableName] = value;
  }
  return retObj;
};

export const defaults = (options, defaults) => {
  return Object.assign({}, defaults, options);
};

export const getEffectiveStartOrEndDate = (isForward, taskOrProject) => {
  return isForward ? taskOrProject.effectiveStartDate : taskOrProject.effectiveEndDate;
};

export const signOfNumber = (number) => {
  return number < 0 ? -1 : 1;
};

// https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
export const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
      }
    );
  });
};

export const isTextInputElement = (element) => {
  if (element == null) {
    return false;
  }
  const tagName = element.tagName.toLowerCase();
  const textTypes = ['color', 'date', 'datetime-local', 'email', 'month', 'number', 'password', 'tel', 'text', 'time', 'url', 'week'];
  return (tagName === 'input' && textTypes.includes(element.getAttribute('type').toLowerCase())) || tagName === 'textarea';
};
