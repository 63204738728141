//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DialogMixin from '~/mixins/DialogMixin'

export default {
  mixins: [DialogMixin],
  props: {
    message: {
      type: String,
      required: true
    },
    primaryButtonLabel: {
      type: String,
      default: 'Ok'
    },
    hasCancelButton: {
      type: Boolean,
      default: false
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    showModalBackground: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    cancel() {
      this.$emit('close', false);
    },
    ok() {
      this.$emit('close', true);
    }
  }
}
