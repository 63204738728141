import { Database } from '@vuex-orm/core';
import Project from '~/models/Project';
import Task from '~/models/Task';
import Event from '~/models/Event';
import AppStateItem from '~/models/AppStateItem';

const database = new Database();

database.register(Project);
database.register(Task);
database.register(Event);
database.register(AppStateItem);

export default database;
