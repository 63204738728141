import TopLevelItem from './TopLevelItem';
import Task from './Task';
import gql from 'graphql-tag'
import AppStateItem from './AppStateItem';

export default class Project extends TopLevelItem {
  static entity = 'project';

  static fields() {
    return {
      ...super.fields(),
      nonWorkingDays: this.attr([]),
      childrenDuration: this.attr(0),
      tasks: this.hasMany(Task, 'projectId'),
      appStateItems: this.hasMany(AppStateItem, 'projectId')
    }
  }

  static readonlyFields() {
    return ['childrenDuration']
  };

  get effectiveDuration() {
    if (this.startDate != null && this.endDate != null) {
      return this.endDateAsDayjs.diff(this.startDateAsDayjs, 'day') + 1;
    } else if (this.duration != null) {
      return this.duration;
    } else if (this.childrenDuration != null) {
      return this.childrenDuration;
    }
  }

  nestedTasks(includeCompleted) {
    const tasks = this.tasks.filter(task => includeCompleted ? true : !task.isCompleted);
    const tasksByParentId = new Map();
    tasks.forEach(task => {
      const parentId = task.parentId ? task.parentId : 'project';
      const tasks = tasksByParentId.get(parentId);
      if (tasks) {
        tasks.push(task);
      } else {
        tasksByParentId.set(parentId, [task]);
      }
    });
    const arrangedTasks = [];
    const isForward = this.startDate != null;
    this.childrenDuration = this._addChildTasks(isForward, this, this, 'project', 0, 0, arrangedTasks, tasksByParentId);
    Project.update({ id: this.id, childrenDuration: this.childrenDuration });
    return arrangedTasks;
  }

  _addChildTasks(isForward, project, parent, parentId, insertionIndex, depth, arrangedTasks, tasksByParentId) {
    const tasks = tasksByParentId.get(parentId)?.sort((task1, task2) => task1.index - task2.index);
    let totalDuration = 0;
    if (tasks) {
      parent.hasChildren = true;
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        if (i === 0) {
          if (isForward) {
            // start date = parent start date + delay
            task.effectiveStartDate = parent.effectiveStartDate?.add(task.effectiveDelay, 'day');
            //console.log(`${indent(depth)}${task.title}: ${task.effectiveStartDate.format('D/M')}`);
          } else {
            // end date = parent end date
            task.effectiveEndDate = parent.effectiveEndDate;
            //console.log(`${indent(depth)}${task.title}: ${task.effectiveEndDate.format('D/M')}`);
          }
        } else {
          const previousTask = tasks[i - 1];
          if (isForward) {
            // start date = previous start date + previous duration + delay
            task.effectiveStartDate = previousTask.effectiveStartDate?.add(previousTask.getTotalDuration(true) +
              task.effectiveDelay, 'day');
            //console.log(`${indent(depth)}${task.title}: ${task.effectiveStartDate.format('D/M')}`);
          } else {
            // end date = previous end date - previous duration - previous delay
            task.effectiveEndDate = previousTask.effectiveEndDate?.subtract(previousTask.getTotalDuration(false) +
              previousTask.effectiveDelay, 'day');
            //console.log(`${indent(depth)}${task.title}: ${task.effectiveEndDate.format('D/M')}`);
          }
        }
        task.project = project;
        task.parent = parent;
        task.depth = depth;
        if (isForward) {
          arrangedTasks.push(task);
        } else {
          arrangedTasks.splice(insertionIndex, 0, task);
        }
        task.childrenDuration = this._addChildTasks(isForward, project, task, task.id, insertionIndex + 1,
          depth + 1, arrangedTasks, tasksByParentId);
        totalDuration += task.effectiveDelay + task.getTotalDuration(isForward);
      }
    }
    return totalDuration;
  }

  _updateOnServer(obj) {
    return this.client.mutate({
      mutation: gql`
          mutation projectUpdate($input: ProjectUpdateInput!) {
              projectUpdate(project: $input) {
                  ok
              }
          }`,
      variables: {
        input: obj
      }
    });
  }

  static _updateAllOnServer(objs) {
    return this.client.mutate({
      mutation: gql`
          mutation projectsUpdate($input: [ProjectUpdateInput!]!) {
              projectsUpdate(projects: $input) {
                  ok
              }
          }`,
      variables: {
        input: this._withServerIds(objs)
      }
    });
  }

  async _createOnServer(obj) {
    const response = await this.client.mutate({
      mutation: gql`
          mutation projectCreate($input: ProjectCreateInput!) {
              projectCreate(project: $input) {
                  id
              }
          }`,
      variables: {
        input: obj
      }
    });
    const result = await this.insertOrUpdate(response.data.projectCreate.id, obj);
    return result.project[0];
  }

  _deleteOnServer() {
    return this.client.mutate({
      mutation: gql`
          mutation projectDelete($id: Int!) {
              projectDelete(id: $id) {
                  ok
              }
          }`,
      variables: {
        id: this.id
      }
    });
  }
}
