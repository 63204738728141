export default {
  data() {
    return {
      changePasswordDialog: {
        visible: false,
        token: null,
        email: null
      }
    }
  },
  methods: {
    showChangePasswordDialog(token, email) {
      this.changePasswordDialog.visible = true;
      this.changePasswordDialog.token = token;
      this.changePasswordDialog.email = email;
    },
    hideChangePasswordDialog() {
      this.changePasswordDialog.visible = false;
    }
  }
}
