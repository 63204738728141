//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from 'vuelidate/lib/validators'
import DialogMixin from '~/mixins/DialogMixin'
import Divider from './Divider'

export default {
  components: {
    Divider
  },
  mixins: [DialogMixin],
  data() {
    return {
      email: '',
      password: ''
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isProgress = true;
          await this.$store.dispatch('login', { email: this.email, password: this.password });
          this.isProgress = false;
          this.$emit('close');
          this.$router.push({ name: 'projects' });
          // For testing toasts
          // this.$toast.default("You will need to confirm your email address by clicking on the link in the email that has just been sent to you. If you don't find it in your inbox check your spam folder.");
        } catch (error) {
          this.isProgress = false;
          this.$emit('error', { error });
        }
      }
    },

    logInFacebook() {
      // TODO
      console.log('Log in with Facebook');
    },

    logInGoogle() {
      // TODO
      console.log('Log in with Google')
    }
  }
}
