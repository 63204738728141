import gql from 'graphql-tag'
import Constants from '~/constants';
import VuexORM from '@vuex-orm/core';
import Project from '~/models/Project';
import Event from '~/models/Event';
import Task from '~/models/Task';
import AppStateItem from '~/models/AppStateItem';
import database from './database';

export const plugins = [VuexORM.install(database)];

export const state = () => ({
  isLoggedIn: false,
  haveArchivedProjectsAndEvents: false
});

export const mutations = {
  login(state) {
    state.isLoggedIn = true;
  },
  logout(aState) {
    Object.assign(aState, state());
  },
  haveArchivedProjectsAndEvents(state, payload) {
    state.haveArchivedProjectsAndEvents = payload;
  }
};

export const actions = {
  async login({ commit }, { email, password }) {
    const client = this.app.apolloProvider.defaultClient;
    const response = await client.mutate({
      mutation: gql`
          mutation login($email:String!, $password:String!) {
              login(email: $email, password: $password) {
                  token
              }
          }`,
      variables: { email, password }
    }).then(({ data }) => data && data.login);
    await this.app.$apolloHelpers.onLogin(response.token, undefined, { expires: Constants.TOKEN_EXPIRES });
    commit('login');
  },

  async signup({ commit }, { email, password, firstName, lastName }) {
    const client = this.app.apolloProvider.defaultClient;
    const response = await client.mutate({
      mutation: gql`
          mutation signup($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
              signup(email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
                  token
              }
          }`,
      variables: { email, password, firstName, lastName }
    }).then(({ data }) => data && data.signup);
    // TODO: send confirmation email
    // TODO: put expires in config
    await this.app.$apolloHelpers.onLogin(response.token, undefined, { expires: Constants.TOKEN_EXPIRES });
    commit('login');
  },

  resetPassword(_, { email }) {
    const client = this.app.apolloProvider.defaultClient;
    return client.mutate({
      mutation: gql`
          mutation resetPassword($email: String!) {
              resetPassword(email: $email) {
                  ok
              }
          }`,
      variables: { email }
    });
  },

  changePassword(_, { token, email, password }) {
    const client = this.app.apolloProvider.defaultClient;
    return client.mutate({
      mutation: gql`
          mutation changePassword($token: String!, $email: String!, $password: String!) {
              changePassword(token: $token, email: $email, password: $password) {
                  ok
              }
          }`,
      variables: { token, email, password }
    });
  },

  sendMessage(_, { name, email, message }) {
    const client = this.app.apolloProvider.defaultClient;
    return client.mutate({
      mutation: gql`
          mutation sendMessage($name: String!, $email: String!, $message: String!) {
              sendMessage(name: $name, email: $email, message: $message) {
                  ok
              }
          }`,
      variables: { name, email, message }
    });
  },

  async getAppState({ commit }) {
    const client = this.app.apolloProvider.defaultClient;
    const response = await client.query({
      query: gql`
          query {
              appStateItems {
                  id,
                  key,
                  value
              }
          }
      `
    });
    await AppStateItem.insert({ data: response.data.appStateItems });
  },

  async getProjectsAndEvents({ commit }, { isArchived, includeArchived }) {
    const client = this.app.apolloProvider.defaultClient;
    const response = await client.query({
      query: gql`
          query($isArchived: Boolean, $includeArchived: Boolean) {
              projects(isArchived: $isArchived, includeArchived: $includeArchived) {
                  id
                  title
                  index
                  isArchived
                  startDate
                  endDate
                  duration
                  childrenDuration
                  note
              }     
              events(isArchived: $isArchived, includeArchived: $includeArchived) {
                  id
                  title
                  index
                  startDate
                  endDate
                  duration
                  note 
              }
          }`,
      variables: { isArchived, includeArchived }
    });
    await Project.insert({ data: response.data.projects });
    await Event.insert({ data: response.data.events });
    if (isArchived || includeArchived) {
      commit('haveArchivedProjectsAndEvents', true);
    }
  },

  async getProject({ commit }, { id }) {
    const client = this.app.apolloProvider.defaultClient;
    const response = await client.query({
      query: gql`
          query($id: Int!) {
              project(id: $id) {
                  id
                  title
                  index
                  isArchived
                  nonWorkingDays
                  startDate
                  endDate
                  duration
                  childrenDuration
                  note
                  tasks {
                    id
                    projectId  
                    parentId
                    title
                    index
                    isCompleted
                    duration
                    delay
                    note
                    appStateItems {
                        id
                        key
                        value
                    }  
                  }
                  appStateItems {
                      id
                      key
                      value
                  }
              }
              appStateItems {
                  id
                  key
                  value
              }
          }`,
      variables: { id }
    });
    // TODO: can this be optimised if the user app state items are already in the store?
    await AppStateItem.insert({ data: response.data.appStateItems });
    await Project.insert({ data: response.data.project });
  },

  async getTasks({ commit }, { projectId }) {
    const client = this.app.apolloProvider.defaultClient;
    const response = await client.query({
      query: gql`
          query($projectId: Int!) {
              tasks(projectId: $projectId) {
                  id
                  projectId
                  parentId
                  title
                  index
                  isCompleted
                  duration
                  note
              }
          }`,
      variables: { projectId }
    });
    // TODO: what happens if the tasks are already in the store?
    await Task.insert({ data: response.data.tasks });
  },

  async logout({ commit, dispatch }) {
    await this.app.$apolloHelpers.onLogout();
    await dispatch('entities/deleteAll');
    commit('logout');
  },

  async nuxtServerInit({ commit }) {
    const token = await this.app.$apolloHelpers.getToken();
    if (token != null) {
      commit('login');
    }
  }
};
