import dayjs from 'dayjs';
import Constants from '~/constants';
import Item from './Item';

export default class TopLevelItem extends Item {
  static fields() {
    return {
      ...super.fields(),
      isArchived: this.attr(false),
      startDate: this.attr(null),
      endDate: this.attr(null)
    }
  }

  static requiredFieldsForCreate() {
    return ['title', 'index'];
  }

  get startDateAsDayjs() {
    if (this.startDate != null) {
      return dayjs(this.startDate, Constants.MODEL_DATE_FORMAT);
    }
    return this.startDate;
  }

  set startDateAsDayjs(dayjs) {
    this._setStartDate(dayjs, dayjs => dayjs.format(Constants.MODEL_DATE_FORMAT));
  }

  get startDateAsDate() {
    if (this.startDate != null) {
      return new Date(this.startDate);
    }
    return this.startDate;
  }

  set startDateAsDate(date) {
    this._setStartDate(date, date => dayjs(date).format(Constants.MODEL_DATE_FORMAT));
  }

  get endDateAsDayjs() {
    if (this.endDate != null) {
      return dayjs(this.endDate, Constants.MODEL_DATE_FORMAT);
    }
    return this.endDate;
  }

  set endDateAsDayjs(dayjs) {
    this._setEndDate(dayjs, dayjs => dayjs.format(Constants.MODEL_DATE_FORMAT));
  }

  get endDateAsDate() {
    if (this.endDate != null) {
      return new Date(this.endDate);
    }
    return this.endDate;
  }

  set endDateAsDate(date) {
    this._setEndDate(date, date => dayjs(date).format(Constants.MODEL_DATE_FORMAT));
  }

  // returns dayjs
  get effectiveStartDate() {
    if (this.startDate != null) {
      return this.startDateAsDayjs;
    } else if (this.endDate != null && this.effectiveDuration > 0) {
      return this.endDateAsDayjs.subtract(this.effectiveDuration - 1, 'day');
    }
  }

  get effectiveStartDateAsDate() {
    const dayjs = this.effectiveStartDate;
    if (dayjs != null) {
      return dayjs.toDate();
    }
  }

  // returns dayjs
  get effectiveEndDate() {
    if (this.endDate != null) {
      return this.endDateAsDayjs;
    } else if (this.startDate != null && this.effectiveDuration > 0) {
      return this.startDateAsDayjs.add(this.effectiveDuration - 1, 'day');
    }
  }

  get effectiveEndDateAsDate() {
    const dayjs = this.effectiveEndDate;
    if (dayjs != null) {
      return dayjs.toDate();
    }
  }

  get hasFixedStart() {
    return this.startDate && this.effectiveDuration;
  }

  get hasFixedEnd() {
    return this.endDate && this.effectiveDuration;
  }

  isValidTitle(title) {
    return title != null && title.length > 0;
  }

  isValidStartDate(date) {
    if (date == null) {
      // You can only clear start date OR end date
      return this.endDate != null;
    } else if (this.endDate != null) {
      // Start date needs to be before end date
      return this.endDateAsDayjs.diff(dayjs(date), 'day') >= 0;
    } else {
      return true;
    }
  }

  isValidEndDate(date) {
    if (date == null) {
      // You can only clear start date OR end date
      return this.startDate != null;
    } else if (this.startDate != null) {
      // End date needs to be after start date
      return dayjs(date).diff(this.startDateAsDayjs, 'day') >= 0;
    } else {
      return true;
    }
  }

  _setStartDate(value, toStringFunc) {
    if (value != null) {
      this.startDate = toStringFunc(value);
      this._mightClearDuration();
    } else {
      this._mightSetDuration();
      this.startDate = null;
    }
  }

  _setEndDate(value, toStringFunc) {
    if (value != null) {
      this.endDate = toStringFunc(value);
      this._mightClearDuration();
    } else {
      this._mightSetDuration();
      this.endDate = null;
    }
  }

  _mightClearDuration() {
    if (this.startDate && this.endDate) {
      this.duration = null;
    }
  }

  _mightSetDuration() {
    if (this.startDate && this.endDate) {
      this.duration = this.endDateAsDayjs.diff(this.startDateAsDayjs, 'day') + 1;
    }
  }
}
