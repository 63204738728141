import Project from './Project';
import AppStateItem from './AppStateItem';
import gql from 'graphql-tag';
import { defaults } from '~/utils/misc';
import dayjs from 'dayjs';
import Item from './Item';
import { getEffectiveStartOrEndDate } from '~/utils/misc';
import { signDays} from '~/utils/date';
import AppStateKeys from '~/constants/AppStateKeys';
import AppStateMixin from './AppStateMixin';

export default class Task extends AppStateMixin(Item) {
  static entity = 'task';

  static fields() {
    return {
      ...super.fields(),
      isCompleted: this.attr(false),
      delay: this.attr(null),
      parentId: this.attr(null),
      parent: this.belongsTo(Task, 'parentId'),
      projectId: this.attr(null),
      project: this.belongsTo(Project, 'projectId'),
      tasks: this.hasMany(Task, 'parentId'),
      appStateItems: this.hasMany(AppStateItem, 'taskId')
    }
  }

  static requiredFieldsForCreate() {
    return ['projectId', 'parentId', 'title', 'index'];
  }

  get isEffectiveCompleted() {
    return this.isCompleted || this.parent?.isEffectiveCompleted;
  }

  get isParentCompleted() {
    return this.parent?.isCompleted || this.parent?.isParentCompleted;
  }

  get isHidden() {
    return this.parent?.isChildrenHidden || this.parent?.isHidden;
  }

  get effectiveDelay() {
    return this.delay != null ? this.delay : 0;
  }

  get isChildrenHidden() {
    return this._getAppStateAsBoolean(AppStateKeys.IS_CHILDREN_HIDDEN, false);
  }

  set isChildrenHidden(value) {
    return this._setAppState(AppStateKeys.IS_CHILDREN_HIDDEN, value, { taskId: this.id });
  }

  getNumNonWorkingDaysInDuration(isForward = true) {
    if ((isForward && this.effectiveStartDate == null) || (!isForward && this.effectiveEndDate == null) || this.project.nonWorkingDays == null
      || this.project.nonWorkingDays.length === 0 || this.duration == null) {
      return 0;
    }
    const duration = this.effectiveDuration;
    if (duration === 0) {
      return 0;
    }
    let date = getEffectiveStartOrEndDate(isForward, this);
    let numDays = 0;
    let i = 0;
    while (i < duration) {
      if (this.project.nonWorkingDays.includes(date.day())) {
        numDays++;
      } else {
        i++;
      }
      date = date.add(signDays(isForward, 1), 'day');
    }
    return numDays;
  }

  // duration or duration of children
  get effectiveDuration() {
    return this.duration != null ? this.duration : this.childrenDuration != null ? this.childrenDuration : 0;
  }

  // duration including non working days
  getTotalDuration(isForward = true) {
    return this.effectiveDuration + this.getNumNonWorkingDaysInDuration(isForward);
  }

  // displays duration as a line - duration computed from children
  get displayAsComputed() {
    return this.duration == null && this.effectiveDuration > 0;
  }

  get effectiveStartDate() {
    if (this._effectiveStartDate != null) {
      return this._effectiveStartDate;
    } else if (this._effectiveEndDate != null) {
      return this._effectiveEndDate.subtract(this.getTotalDuration(false) - 1, 'day');
    }
    return null;
  }

  set effectiveStartDate(value) {
    this._effectiveStartDate = value;
  }

  get effectiveEndDate() {
    if (this._effectiveEndDate != null) {
      return this._effectiveEndDate;
    } else if (this._effectiveStartDate != null) {
      return this._effectiveStartDate.add(this.getTotalDuration(true) - 1, 'day');
    }
    return null;
  }

  set effectiveEndDate(value) {
    this._effectiveEndDate = value;
  }

  get hasFixedStart() {
    return !this.displayAsComputed;
  }

  get hasFixedEnd() {
    return !this.displayAsComputed;
  }

  isValidStartDate(date) {
    if (date == null) {
      // For the first task there needs to be a start date or end date
      return !this.isFirst || this.endDate != null;
    } else if (this.endDate != null) {
      // Start date needs to be before end date
      return this.endDateAsDayjs.diff(dayjs(date), 'day') >= 0;
    } else {
      return true;
    }
  }

  isValidEndDate(date) {
    if (date == null) {
      // For the first task there needs to be a start date or end date
      return !this.isFirst || this.startDate != null;
    } else if (this.startDate != null) {
      // End date needs to be after start date
      return dayjs(date).diff(this.startDateAsDayjs, 'day') >= 0;
    } else {
      return true;
    }
  }

  get isFirst() {
    return this.index === 0 && this.parentId === this.projectId;
  }

  async _updateOnServer(obj) {
    return this.client.mutate({
      mutation: gql`
        mutation taskUpdate($input: TaskUpdateInput!) {
            taskUpdate(task: $input) {
                ok
            }
        }`,
      variables: {
        input: obj
      }
    });
  }

  static async _updateAllOnServer(objs) {
    return this.client.mutate({
      mutation: gql`
          mutation tasksUpdate($input: [TaskUpdateInput!]!) {
              tasksUpdate(tasks: $input) {
                  ok
              }
          }`,
      variables: {
        input: this._withServerIds(objs)
      }
    });
  }

  async _createOnServer(obj) {
    const response = await this.client.mutate({
      mutation: gql`
          mutation taskCreate($input: TaskCreateInput!) {
              taskCreate(task: $input) {
                  id
              }
          }`,
      variables: {
        input: obj
      }
    });
    const result = await this.insertOrUpdate(response.data.taskCreate.id, obj);
    return result.task[0];
  }

  // override
  async delete(options = {}) {
    const actual = defaults(options, {
      updateServer: true,
      updateServerFirst: true,
      revertUpdateAll: false
    });
    if (!actual.updateServerFirst) {
      await this._cascadeDelete();
    }
    if (actual.updateServer && this.hasServerId) {
      await this.client.mutate({
        mutation: gql`
            mutation taskDelete($id: Int!) {
                taskDelete(id: $id) {
                    ok
                }
            }`,
        variables: {
          id: this.id
        }
      });
    }
    if (actual.updateServerFirst) {
      await this._cascadeDelete();
    }
    if (actual.revertUpdateAll) {
      await Task._revertUpdateAll();
    }
  }

  async _cascadeDelete() {
    await Task.delete(this.id);
    await this._deleteChildren(this.id);
  }

  async _deleteChildren(parentId) {
    const children = Task.query().where('parentId', parentId).get();
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      await this._deleteChildren(child.id);
      await Task.delete(child.id);
    }
  }
}
