export const dateInDays = (dayjsDate) => {
  if (dayjsDate != null) {
    return Math.floor(dayjsDate.unix() / (60 * 60 * 24));
  }
};

export const isWeekend = (dayjsDate) => {
  return dayjsDate.day() === 0 || dayjsDate.day() === 6;
};

// date: dayjs
export const getNumNonWorkingDays = (date, deltaDay, nonWorkingDays) => {
  if (deltaDay === 0 || nonWorkingDays.length === 0) {
    return 0;
  }
  let itDate = deltaDay < 0 ? date.add(deltaDay + 1, 'day') : date.add(1, 'day');
  let numDays = 0;
  for (let i = 0; i < Math.abs(deltaDay); i++) {
    if (nonWorkingDays.includes(itDate.day())) {
      numDays++;
    }
    itDate = itDate.add(1, 'day');
  }
  return numDays;
};

export const signDays = (isForward, days) => {
  return isForward ? days : -days;
};
