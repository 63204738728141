//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from 'vuelidate/lib/validators'
import DialogMixin from '~/mixins/DialogMixin'

export default {
  mixins: [DialogMixin],
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      email
    },
    message: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isProgress = true;
          await this.$store.dispatch('sendMessage', { name: this.name, email: this.email, message: this.message });
          this.isProgress = false;
          this.$emit('close');
          this.$toast.default('Your message has been sent.');
        } catch (error) {
          this.isProgress = false;
          this.$emit('error', { error });
        }
      }
    }
  }
}
