import TopLevelItem from './TopLevelItem';
import gql from 'graphql-tag';

export default class Event extends TopLevelItem {
  static entity = 'event';

  get effectiveDuration() {
    if (this.startDate != null && this.endDate != null) {
      return this.endDateAsDayjs.diff(this.startDateAsDayjs, 'day') + 1;
    } else if (this.duration != null) {
      return this.duration;
    }
  }

  _updateOnServer(obj) {
    return this.client.mutate({
      mutation: gql`
          mutation eventUpdate($input: EventUpdateInput!) {
              eventUpdate(event: $input) {
                  ok
              }
          }`,
      variables: {
        input: obj
      }
    });
  }

  static _updateAllOnServer(objs) {
    return this.client.mutate({
      mutation: gql`
          mutation eventsUpdate($input: [EventUpdateInput!]!) {
              eventsUpdate(events: $input) {
                  ok
              }
          }`,
      variables: {
        input: this._withServerIds(objs)
      }
    });
  }

  async _createOnServer(obj) {
    const response = await this.client.mutate({
      mutation: gql`
          mutation eventCreate($input: EventCreateInput!) {
              eventCreate(event: $input) {
                  id
              }
          }`,
      variables: {
        input: obj
      }
    });
    const result = await this.insertOrUpdate(response.data.eventCreate.id, obj);
    return result.event[0];
  }

  _deleteOnServer() {
    return this.client.mutate({
      mutation: gql`
          mutation eventDelete($id: Int!) {
              eventDelete(id: $id) {
                  ok
              }
          }`,
      variables: {
        id: this.id
      }
    });
  }
}
