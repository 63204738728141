//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutMixin from '~/mixins/LayoutMixin';

export default {
  mixins: [LayoutMixin],
  methods: {
    showProjectSettings() {
      this.$nuxt.$emit('showProjectSettings');
    }
  }
}
