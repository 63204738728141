import { Model } from '@vuex-orm/core';
import { defaults } from '~/utils/misc';

export default class BaseModel extends Model {
  static fields() {
    return {
      id: this.attr(null)
    }
  }

  constructor(record = undefined) {
    super(record);
    this.client = this.$store().app.apolloProvider.defaultClient;
  }

  static get client() {
    return this.store().app.apolloProvider.defaultClient;
  }

  async save(options = {}) {
    const actual = defaults(options, {
      commitUpdateAll: false
    });
    if (this.hasServerId) {
      await this.update(options);
    } else {
      await this.create(options);
    }
    if (actual.commitUpdateAll) {
      await this.constructor._commitUpdateAll();
    }
  }

  async update(options = {}) {
    const actual = defaults(options, {
      updateServer: true,
      updateServerFirst: true,
      fields: undefined
    });
    const obj = this.asObject({ includeFields: actual.fields });
    if (!actual.updateServerFirst) {
      await this.constructor.update(obj);
    }
    if (actual.updateServer) {
      try {
        await this._updateOnServer(obj);
      } catch (error) {
        this._rollback();
        throw error;
      }
    }
    if (actual.updateServerFirst) {
      await this.constructor.update(obj);
    }
  }

  static async updateAll(items, options = {}) {
    const actual = defaults(options, {
      fields: undefined,
      updateServer: true,
      updateServerFirst: true,
      tentative: false
    });
    const objs = this._asObjects(items, actual.fields);
    if (!actual.updateServerFirst) {
      await this.update({ data: objs });
    }
    if (actual.updateServer && !actual.tentative) {
      await this._updateAllOnServer(objs);
    }
    if (actual.updateServerFirst) {
      await this.update({ data: objs });
    }
  }

  async create(options = {}) {
    const actual = defaults(options, {
      fields: undefined,
      rollbackOnError: true
    });
    if (actual.fields != null && this.constructor.requiredFieldsForCreate) {
      const requiredFields = this.constructor.requiredFieldsForCreate();
      requiredFields.forEach((field) => {
        if (!actual.fields.includes(field)) {
          actual.fields.push(field);
        }
      });
    }
    const obj = this.asObject({ excludeFields: ['id'], includeFields: actual.fields });
    try {
      return await this._createOnServer(obj);
    } catch (error) {
      this._rollback();
      throw error;
    }
  }

  async delete(options = {}) {
    const actual = defaults(options, {
      updateServer: true,
      updateServerFirst: true
    });
    if (!actual.updateServerFirst) {
      await this.constructor.delete(this.id);
    }
    if (actual.updateServer && this.hasServerId) {
      await this._deleteOnServer();
    }
    if (actual.updateServerFirst) {
      await this.constructor.delete(this.id);
    }
  }

  saveState(options = {}) {
    const actual = defaults(options, {
      fields: null
    });
    this.savedState = this.asObject({ includeFields: actual.fields });
  }

  async insertOrUpdate(id, obj) {
    obj.id = id;
    const result = await this.constructor.insert({ data: obj });
    if (!this.hasServerId) {
      // Remove the record with a temporary ID
      await this.constructor.delete(this.id);
    }
    this.$id = String(id);
    this.id = id;
    return result;
  }

  get hasServerId() {
    return !isNaN(Number(this.id));
  }

  // Updates index property to match order of items
  static updateIndices(items) {
    const itemIndices = items.map(item => item.index).sort();
    items.forEach((item, index) => {
      item.index = itemIndices[index];
    });
  }

  static saveState(items, includeFields = undefined) {
    const ids = items.map(item => item.id);
    const storeItems = this.findIn(ids);
    this.savedState = this._asObjects(storeItems, includeFields);
  }

  // only includes attributes and not relationships
  // options:
  //   includeFields: ['field']
  //   excludeFields: ['field']
  asObject(options = {}) {
    const retObj = {};
    const fields = this.$fields();
    for (const field of Object.keys(fields)) {
      const value = fields[field];
      if (value.isNullable !== undefined &&
        (options.includeFields == null || options.includeFields.includes(field) || field === 'id') &&
        (options.excludeFields == null || !options.excludeFields.includes(field)) &&
        (this.constructor.readonlyFields == null || !this.constructor.readonlyFields().includes(field)) &&
        (field !== 'id' || (field === 'id' && this[field] != null))) {
        retObj[field] = this[field];
      }
    }
    return retObj;
  }

  static _asObjects(items, includeFields = undefined) {
    return items.map(item => item.asObject({ includeFields }));
  }

  static _withServerIds(objs) {
    // entities without a server ID will have an ID like "$uid1"
    return objs.filter(obj => !isNaN(Number(obj.id)));
  }

  static _revertUpdateAll() {
    if (this.savedState && this.savedState.length > 0) {
      this.update({ data: this.savedState });
      this.savedState = null;
    }
  }

  static async _commitUpdateAll() {
    if (this.savedState && this.savedState.length > 0) {
      const ids = this.savedState.map(item => item.id);
      const fields = [];
      for (const field of Object.keys(this.savedState[0])) {
        if (field !== 'id') {
          fields.push(field);
        }
      }
      const items = this.findIn(ids);
      const objs = this._asObjects(items, fields);
      await this._updateAllOnServer(objs);
      this.savedState = null;
    }
  }

  _rollback() {
    if (this.savedState) {
      for (const key of Object.keys(this.savedState)) {
        this[key] = this.savedState[key];
      }
      this.savedState = null;
    }
  }
}
