import { getErrorMessage } from '~/utils/error';
import { addFullstop } from '~/utils/string';
import { defaults } from '~/utils/misc';

const defaultValues = {
  primaryButtonLabel: 'OK',
  hasCancelButton: false,
  hasCloseButton: true,
  okFunc: null,
  showModalBackground: false
};

export default {
  data() {
    return {
      basicDialog: {
        visible: false,
        message: null,
        primaryButtonLabel: 'OK',
        hasCancelButton: false,
        hasCloseButton: true,
        errorMessage: null,
        okFunc: null,
        showModalBackground: false
      }
    }
  },
  methods: {
    showBasicDialog(message, options = {}) {
      const actual = defaults(options, defaultValues);
      Object.assign(this.basicDialog, actual, { visible: true, message });
    },
    showErrorDialog(options = {}) {
      const actual = defaults(options, defaultValues);
      let errorMessage = options.message ? options.message : '';
      if (options.error) {
        errorMessage += ` ${addFullstop(getErrorMessage(options.error))}`;
      }
      Object.assign(this.basicDialog, actual, { visible: true, message: errorMessage });
    },
    async hideBasicDialog(isOkAction) {
      this.basicDialog.visible = false;
      if (isOkAction && this.basicDialog.okFunc) {
        try {
          await this.basicDialog.okFunc();
        } catch (error) {
          if (this.basicDialog.errorMessage) {
            this.showErrorDialog(this.basicDialog.errorMessage, error);
          }
        }
      }
    }
  }
}
