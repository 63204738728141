//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import DialogMixin from '~/mixins/DialogMixin'
import { getAppErrorCode, getGraphQLErrorMessage } from '~/utils/error';
import GQLErrorCodes from '~/constants/GQLErrorCodes';

export default {
  mixins: [DialogMixin],
  props: {
    email: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      password: '',
      passwordConfirmation: ''
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    passwordConfirmation: {
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isProgress = true;
          await this.$store.dispatch('changePassword', {
            token: this.token,
            email: this.email,
            password: this.password
          });
          this.isProgress = false;
          this.$emit('close');
        } catch (error) {
          this.isProgress = false;
          if (getAppErrorCode(error) === GQLErrorCodes.INVALID_RESET_PASSWORD_TOKEN) {
            const message = `${getGraphQLErrorMessage(error)} Do you want to resend the password reset email?`;
            const options = {
              primaryButtonLabel: 'Resend Email',
              hasCancelButton: true,
              okFunc: async () => {
                await this.$store.dispatch('resetPassword', {
                  email: this.email
                });
                this.$emit('close');
                this.$toast.default("You will need to click on the link in the email that has just been sent to you. If you don't find it in your inbox check your spam folder.");
              }
            };
            this.$emit('message', message, options);
          } else {
            this.$emit('error', { error });
          }
        }
      }
    }
  }
}
