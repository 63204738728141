export default {
  data() {
    return {
      menuDisplay: null,
      isMenuVisible: false
    }
  },
  methods: {
    showPrimaryMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      this.menuDisplay = this.isMenuVisible ? 'block' : 'none';
    },

    async logOut() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'index' });
    }
  }
}
