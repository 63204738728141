import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast, {
  position: 'top',
  duration: 10000,
  dismissible: true,
  pauseOnHover: true
});
