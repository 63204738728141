import BaseModel from '~/models/BaseModel';
import Project from '~/models/Project';
import Task from '~/models/Task';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import Constants from '~/constants';

export default class AppStateItem extends BaseModel {
  static entity = 'appStateItem';

  static fields() {
    return {
      ...super.fields(),
      key: this.attr(null),
      value: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, 'projectId'),
      taskId: this.attr(null),
      task: this.belongsTo(Task, 'taskId')
    }
  }

  static fromDayjsDate(value) {
    return value != null ? value.format(Constants.MODEL_DATE_FORMAT) : null;
  }

  get asNumber() {
    if (this.value != null) {
      return parseFloat(this.value);
    }
  }

  set asNumber(value) {
    this.value = value != null ? value.toString() : null;
  }

  get asBoolean() {
    if (this.value != null) {
      if (this.value === 'true') {
        return true;
      } else if (this.value === 'false') {
        return false;
      }
    }
  }

  get asDayjsDate() {
    if (this.value != null) {
      return dayjs(this.value, Constants.MODEL_DATE_FORMAT);
    }
  }

  set asDayjsDate(value) {
    this.value = value != null ? value.format(Constants.MODEL_DATE_FORMAT) : null;
  }

  _updateOnServer(obj) {
    return this.client.mutate({
      mutation: gql`
          mutation projectUpdate($input: AppStateItemUpdateInput!) {
              appStateItemUpdate(appStateItem: $input) {
                  ok
              }
          }`,
      variables: {
        input: obj
      }
    });
  }

  async _createOnServer(obj) {
    const response = await this.client.mutate({
      mutation: gql`
          mutation appStateItemCreate($input: AppStateItemCreateInput!) {
              appStateItemCreate(appStateItem: $input) {
                  id
              }
          }`,
      variables: {
        input: obj
      }
    });
    const result = await this.insertOrUpdate(response.data.appStateItemCreate.id, obj);
    return result.appStateItem[0];
  }

  _deleteOnServer() {
    return this.client.mutate({
      mutation: gql`
          mutation appStateItemDelete($id: Int!) {
              appStateItemDelete(id: $id) {
                  ok
              }
          }`,
      variables: {
        id: this.id
      }
    });
  }
}
