import { onError } from 'apollo-link-error';
import GQLErrorCodes from '~/constants/GQLErrorCodes';
import { promiseToObservable } from '~/utils/misc';

export default (ctx) => {
  const link = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        if (error.extensions?.code === GQLErrorCodes.UNAUTHENTICATED) {
          return promiseToObservable(ctx.app.store.dispatch('logout').then(() => {
            ctx.app.router.push({ name: 'index', query: { action: 'login' } });
          }));
        }
      }
    }
  });

  return {
    httpEndpoint: ctx.env.graphqlEndpoint,
    link
  }
}
