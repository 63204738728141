export default {
  TIMELINE_START_DATE: 'timelineStartDate',
  TIMELINE_OFFSET_X: 'timelineOffsetX',
  IS_EVENTS_VISIBLE: 'isEventsVisible',
  IS_ARCHIVED_VISIBLE: 'isArchivedVisible',
  IS_COMPLETED_VISIBLE: 'isCompletedVisible',
  IS_CHILDREN_HIDDEN: 'isChildrenHidden',
  TODO_LIST_PANE_WIDTH: 'todoListPaneWidth',
  IS_PROJECT_KEYBOARD_SHORTCUTS_VISIBLE: 'isProjectKeyboardShortcutsVisible'
}
