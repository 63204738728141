export default {
  data() {
    return {
      isProgress: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
