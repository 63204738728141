export const decapitalizeFirstLetter = (str) => {
  return str.substr(0, 1).toLowerCase() + str.substr(1);
};

export const capitalizeFirstLetter = (str) => {
  return str.substr(0, 1).toUpperCase() + str.substr(1);
};

export const indent = (indent, indentSize = 2) => {
  let str = '';
  for (let i = 0; i < indent; i++) {
    for (let k = 0; k < indentSize; k++) {
      str += ' ';
    }
  }
  return str;
};

export const toString = (obj) => {
  if (obj != null) {
    return obj.toString();
  }
};

export const prependHttp = (str) => {
  if (str != null && !str.toLowerCase().startsWith('http://') && !str.toLocaleString().startsWith('https://')) {
    return `http://${str}`;
  } else {
    return str;
  }
};

export const addFullstop = (str) => {
  if (str.substring(str.length - 1) !== '.') {
    return `${str}.`;
  } else {
    return str;
  }
};
